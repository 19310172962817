import { Notification } from 'api/notifications'
import cx from 'classnames'
import { NotificationTags } from 'components/notifications/NotificationTags'
import { useCurrencies } from 'contexts/hooks/useCurrencies'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'
import { ARTWORK_IMG_PLACEHOLDER } from 'utils/image'
import {
  getActionsBehavior,
  getNotificationContent,
  isArtistNotification,
} from 'utils/notifications'
import { sortImages } from 'utils/sort'

const sizes = {
  sm: {
    title: 'text-xs',
    description: 'text-2xs',
    image: 'max-h-[3.75rem] min-h-[3.75rem] min-w-[3.75rem] max-w-[3.75rem]',
  },
  default: {
    title: 'text-base',
    description: 'text-xs',
    image: 'max-h-[3.75rem] min-h-[3.75rem] min-w-[3.75rem] max-w-[3.75rem]',
  },
} as const

const themes = {
  default: {
    imageContainer: 'group-hover/notification:bg-warm-100 bg-warm-50',
    dateTag: 'group-hover/notification:bg-cool-50',
    textContainer: 'group-hover/notification:bg-warm-100',
  },
  gray: {
    imageContainer: '!bg-warm-100',
    dateTag: '!bg-cool-50',
    textContainer: '!bg-warm-100',
  },
}

export type Props = {
  classNames?: {
    root?: string
    imageContainer?: string
    textContainer?: string
  }
  size?: keyof typeof sizes
  theme?: keyof typeof themes
} & Notification

export const NotificationComponent = (props: Props) => {
  const { t } = useTranslation('notifications')
  const { formatCurrency, userCurrency } = useCurrencies()

  const {
    type,
    artwork,
    artist,
    viewed,
    classNames,
    size = 'default',
    theme = 'default',
  } = props
  const content = getNotificationContent(userCurrency, props, t, formatCurrency)
  const { title, titleSecondary, tag, tagTheme, isDanger } = content

  const sizeStyles = sizes[size]
  const themeStyles = !isDanger ? themes[theme] : null

  const artworkImage = artwork?.images?.[0]?.url
  const sortedArtistImages = artist?.images
    ? sortImages(artist.images, 'desc')
    : []
  const artistImage = sortedArtistImages?.[0]?.url

  const _isArtistNotification = isArtistNotification(type)

  return (
    <div
      className={cx(
        'flex overflow-hidden group/notification',
        classNames?.root,
        isDanger &&
          'bg-danger-light border-t-2 border-danger-dark sticky top-[4.5rem]'
      )}
      data-danger={isDanger}
    >
      <div
        className={cx(
          'flex items-center justify-center min-w-[5rem] px-2.5 py-4 transition-colors',
          isDanger && 'bg-danger-light',
          classNames?.imageContainer,
          themeStyles?.imageContainer
        )}
      >
        {!_isArtistNotification && (
          <Image
            src={artworkImage || ARTWORK_IMG_PLACEHOLDER}
            alt={artwork?.name || 'placeholder'}
            width={60}
            height={60}
            className={cx('object-contain', sizeStyles.image)}
          />
        )}

        {_isArtistNotification && artistImage && (
          <Image
            src={artistImage}
            alt={artist?.name || 'artist'}
            width={60}
            height={60}
            className={cx('object-contain rounded-full', sizeStyles.image)}
          />
        )}
      </div>

      <div
        className={cx(
          'w-full px-5 py-4 flex items-center space-x-3 justify-between transition-colors',
          classNames?.textContainer,
          themeStyles?.textContainer
        )}
      >
        <div>
          <NotificationTags
            {...props}
            includeDateTag
            tagTheme={tagTheme || theme}
            className='flex items-center mb-1 space-x-1'
            label={tag}
          />

          <div className='flex w-full items-center justify-between'>
            <h6
              className={cx(
                'font-body font-medium tracking-normal',
                sizeStyles.title
              )}
            >
              {titleSecondary || title}
            </h6>
          </div>

          <p
            className={cx(
              'line-clamp-2 w-full whitespace-pre-line text-xs text-cool-600 space-x-1 text-start',
              sizeStyles.description
            )}
          >
            {!_isArtistNotification && (
              <>
                <span>{artwork?.artists?.[0]?.name}</span>
                <span className='text-cool-400'>|</span>
                <span>{artwork?.name}</span>
              </>
            )}

            {_isArtistNotification && artist && <span>{artist.name}</span>}
          </p>

          <NotificationAction notification={props} content={content} />
        </div>

        {!viewed && (
          <div className='w-2 h-2 bg-primary-default rounded-full min-h-2 min-w-2' />
        )}
      </div>
    </div>
  )
}

interface NotificationActionProps {
  notification: Props
  content: ReturnType<typeof getNotificationContent>
}

const NotificationAction = ({
  notification,
  content,
}: NotificationActionProps) => {
  const { action, secondaryAction, isDanger } = content
  const { primary: onPrimaryAction, secondary: onSecondaryAction } =
    getActionsBehavior(notification)

  if (!action && !secondaryAction) return null

  return (
    <div className='mt-1 space-x-5 flex'>
      {action && onPrimaryAction && (
        <Link
          href={onPrimaryAction}
          className={cx(
            'text-xs underline',
            isDanger && !secondaryAction
              ? 'text-danger-dark'
              : 'text-secondary-default'
          )}
        >
          {action}
        </Link>
      )}

      {secondaryAction && onSecondaryAction && (
        <Link
          href={onSecondaryAction}
          className={cx(
            'text-xs underline',
            isDanger ? 'text-danger-dark' : 'text-secondary-default'
          )}
        >
          {secondaryAction}
        </Link>
      )}
    </div>
  )
}

import classNames from 'classnames'
import { ContainerElement } from 'types'

export function Tag({ className, children }: ContainerElement) {
  return (
    <div
      className={classNames(
        className,
        'py-1 px-2.5 rounded-2.5xl text-xs font-medium'
      )}
    >
      {children}
    </div>
  )
}

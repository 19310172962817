import { NotificationType } from 'api/notifications'
import { Button } from 'components/common/Button'
import { ComponentProps } from 'react'

type ButtonTheme = ComponentProps<typeof Button>['style']

export const TypeToContentMap: Record<
  NotificationType,
  {
    title: string
    /**
     * If defined, it will be displayed in the small notification component (`Notification.tsx`). Otherwise, `title` will be displayed by default both in `Notification` and `ExtendedNotification`.
     */
    titleSecondary?: string
    action?: string
    actionTheme?: ButtonTheme
    secondaryAction?: string
    secondaryActionTheme?: ButtonTheme
    description?: string
  }
> = {
  [NotificationType.ASK_EXPIRED]: {
    title: 'expired_listing',
    action: 'renew_listing',
    actionTheme: 'solid-primary',
  },
  [NotificationType.ASK_EXPIRED_1day]: {
    title: 'your_listing_expires_tomorrow',
    action: 'extend_listing',
    description: 'descriptions.price_artist_artwork',
    actionTheme: 'solid-primary',
  },
  [NotificationType.ASK_EXPIRED_7days]: {
    title: 'your_listing_expires_7_days',
    action: 'extend_listing',
    description: 'descriptions.price_artist_artwork',
    actionTheme: 'solid-primary',
  },
  [NotificationType.BID_EXPIRED]: {
    title: 'expired_offer',
    action: 'renew_offer',
    actionTheme: 'solid-primary',
  },
  [NotificationType.BID_EXPIRED_1day]: {
    title: 'your_offer_expires_tomorrow',
    action: 'extend_offer',
    description: 'descriptions.price_artist_artwork',
    actionTheme: 'solid-primary',
  },
  [NotificationType.BID_EXPIRED_7days]: {
    title: 'your_offer_expires_7_days',
    action: 'extend_offer',
    description: 'descriptions.price_artist_artwork',
    actionTheme: 'solid-primary',
  },
  [NotificationType.NEW_HIGHEST_BID_BUYER]: {
    title: 'new_highest_offer',
    action: 'update_offer',
    actionTheme: 'outline',
    secondaryAction: 'buy_now',
    secondaryActionTheme: 'solid-success',
    description: 'you_are_no_longer_the_highest_offer',
  },
  [NotificationType.NEW_HIGHEST_BID_SELLER]: {
    title: 'new_highest_offer',
    action: 'update_listing',
    actionTheme: 'outline',
    secondaryAction: 'sell_now',
    secondaryActionTheme: 'solid-danger',
  },
  [NotificationType.NEW_LOWEST_ASK_BUYER]: {
    title: 'new_lowest_listing',
    action: 'update_offer',
    actionTheme: 'outline',
    secondaryAction: 'buy_now',
    secondaryActionTheme: 'solid-success',
  },
  [NotificationType.NEW_LOWEST_ASK_SELLER]: {
    title: 'new_lowest_listing',
    action: 'update_listing',
    actionTheme: 'outline',
    secondaryAction: 'sell_now',
    secondaryActionTheme: 'solid-danger',
    description: 'you_are_no_longer_the_lowest_listing',
  },
  [NotificationType.NEW_SALE_FOLLOW_ARTIST]: {
    title: 'new_sale',
  },
  [NotificationType.NEW_SALE_SAVE_ARTWORK]: {
    title: 'new_sale',
  },
  [NotificationType.ORDER_BY_ANOTHER_BUYER]: {
    title: 'new_sale',
    titleSecondary: 'acquired_by_another_buyer',
    description: 'another_buyer_just_acquired_this_work',
    action: 'update_offer',
    actionTheme: 'outline',
    secondaryAction: 'buy_now',
    secondaryActionTheme: 'solid-success',
  },
  [NotificationType.ORDER_CONFIRMATION]: {
    title: 'order_confirmed',
    action: 'view_order_details',
    description: 'descriptions.artwork_artist',
    actionTheme: 'outline',
  },
  [NotificationType.SALE_BY_ANOTHER_SELLER]: {
    title: 'new_sale',
    titleSecondary: 'sold_by_another_seller',
    description: 'another_seller_just_sold_this_work',
    action: 'update_listing',
    actionTheme: 'outline',
    secondaryAction: 'sell_now',
    secondaryActionTheme: 'solid-danger',
  },
  [NotificationType.SALE_CONFIRMATION]: {
    title: 'your_item_sold',
    action: 'view_sale_details',
    description: 'descriptions.artwork_was_sold',
  },
  [NotificationType.SALE_CONFIRMATION_PASS_AUTH]: {
    title: 'your_item_sold',
    action: 'upload_auth_docs',
    description: 'descriptions.artwork_was_sold',
  },
  [NotificationType.NEW_HIGHEST_BID_BUYER_FOLLOW]: {
    title: 'new_highest_offer',
    action: 'buy_now',
    actionTheme: 'solid-success',
    description: 'you_are_no_longer_the_highest_offer',
  },
  [NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST]: {
    title: 'new_highest_offer',
    action: 'buy_now',
    actionTheme: 'solid-success',
  },
  [NotificationType.NEW_HIGHEST_BID_SAVED_ARTWORK]: {
    title: 'new_highest_offer',
    action: 'buy_now',
    actionTheme: 'solid-success',
    description: 'you_are_no_longer_the_highest_offer',
  },
  [NotificationType.NEW_LOWEST_ASK_SELLER_FOLLOW]: {
    title: 'new_lowest_listing',
    action: 'buy_now',
    actionTheme: 'solid-success',
  },
  [NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST]: {
    title: 'new_lowest_listing',
    action: 'buy_now',
    actionTheme: 'solid-success',
  },
  [NotificationType.NEW_LOWEST_ASK_SAVED_ARTWORK]: {
    title: 'new_lowest_listing',
    action: 'buy_now',
    actionTheme: 'solid-success',
  },
  [NotificationType.NEW_LOWEST_ASK_FOLLOW_ARTIST_COMBINED]: {
    title: 'x_new_lowest_listings',
  },
  [NotificationType.NEW_HIGHEST_BID_FOLLOW_ARTIST_COMBINED]: {
    title: 'x_new_highest_offers',
  },
  [NotificationType.SALE]: {
    title: '', // this is determined dynamically
    description: 'descriptions.artwork_artist',
    action: 'view_sale_details',
  },
}

export const DOCUMENT_STATUS = {
  required: 'REQUIRED',
  not_required: 'NOT_REQUIRED',
  submitted: 'SUBMITED',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  not_uploaded: 'NOT_UPLOADED',
}

export const ASK_STATUS = {
  approved: 'Approved',
  pending: 'Pending',
}

export const SHIPMENT_STATUS = {
  DELIVERED: 'Delivered',
  ANNOUNCED: 'Announced',
  ANNOUNCED_NOT_COLLECTED: 'Announced: not collected',
  BEING_ANNOUNCED: 'Being announced',
  ERROR_COLLECTING: 'Error collecting',
  READY_TO_SEND: 'Ready to send',
  SHIPMENT_PICKED_UP_BY_DRIVER: 'Shipment picked up by driver',
  AT_CUSTOMS: 'At Customs',
  AT_SORTING_CENTER: 'At sorting centre',
  AWAITING_CUSTOMER_PICKUP: 'Awaiting customer pickup',
  BEING_SORTED: 'Being sorted',
  DELIVERY_ADDRESS_CHANGED: 'Delivery address changed',
  DELIVERY_ATTEMPT_FAILED: 'Delivery attempt failed',
  DELIVERY_DATE_CHANGED: 'Delivery date changed',
  DELIVERY_DELAYED: 'Delivery delayed',
  DELIVERY_METHOD_CHANGED: 'Delivery method changed',
  DRIVER_EN_ROUTE: 'Driver en route',
  EN_ROUTE_TO_SORTING_CENTER: 'En route to sorting center',
  NO_LABEL: 'No label',
  NOT_SORTED: 'Not sorted',
  PARCEL_EN_ROUTE: 'Parcel en route',
  SORTED: 'SORTED',
  ARTA_COLLECTED: 'Arta_Collected',
  ARTA_IN_TRANSIT: 'Arta_In_Transit',
  ARTA_PENDING: 'Arta_Pending',
  ARTA_CONFIRMED: 'Arta_Confirmed',
  ARTA_COMPLETED: 'Arta_Completed',
  ARTA_CANCELLED: 'Arta_Cancelled',
}

export const NOTIFICATION_STATUS = {
  AUTH_REQUIRED: 'AUTH_REQUIRED',
  AUTH_OVERDUE: 'AUTH_OVERDUE',
  AUTH_REVIEW: 'AUTH_REVIEW',
  AUTH_APPROVED: 'AUTH_APPROVED',
  SHOULD_SHIP: 'SHOULD_SHIP',
  SHIPPING_OVERDUE: 'SHIPPING_OVERDUE',
  AWAITING_LABEL: 'AWAITING_LABEL',
}

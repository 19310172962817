import { Notification } from 'api/notifications'
import classNames from 'classnames'
import { Icon } from 'components/common/Icon'
import { TooltipMenu } from 'components/common/TooltipMenu'
import { NotificationComponent } from 'components/notifications/Notification'
import routes from 'constants/routes'
import { useMarkNotificationViewed } from 'hooks/useMarkNotificationViewed'
import { useUserNotifications } from 'hooks/useUserNotifications'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

type NotificationsContentProps = {
  notifications: Notification[]
}

const NotificationsContent = ({ notifications }: NotificationsContentProps) => {
  const { t } = useTranslation('notifications')
  const { mutateAsync: viewNotification } = useMarkNotificationViewed()

  const onClickNotification = async (n: Notification) => {
    if (!n.viewed) {
      await viewNotification(n.id)
    }
  }

  return (
    <div>
      <div className='p-4 bg-cool-50 border-b border-warm-200 space-x-3 flex sticky top-0 w-full left-0'>
        <p className='text-xl font-medium leading-8 text-cool-900'>
          {t('common:activity')}
        </p>
        <button
          type='button'
          className='text-xs text-secondary-default underline mt-1'
        >
          {t('common:mark_all_as_read')}
        </button>
      </div>

      <ol className='h-full'>
        {notifications.map((notification, index) => (
          <li key={`${notification.type}@${index}`}>
            <button
              className={classNames(
                'w-full',
                index !== 0 && 'border-t-2 border-primary-light'
              )}
              title='View notification'
              onClick={() => onClickNotification(notification)}
            >
              <NotificationComponent {...notification} />
            </button>
          </li>
        ))}
      </ol>

      <div className='flex items-center justify-center border-t-2 border-primary-light py-3 sticky bottom-0 left-0 w-full bg-cool-50'>
        <Link
          href={routes.notifications}
          className='text-sm font-medium text-purple-500 underline'
        >
          {t('common:view_all')}
        </Link>
      </div>
    </div>
  )
}

export const NotificationsPopup = () => {
  const { t } = useTranslation('notifications')

  const {
    data: { notifications = [], notViewedCount = 0 },
  } = useUserNotifications()

  return (
    <TooltipMenu
      content={
        <NotificationsContent notifications={notifications.slice(0, 6)} />
      }
      placement='bottom-left'
      className='p-2 hover:bg-warm-100 rounded-full transition-colors'
      contentClassName='mt-1 max-w-[26.25rem] min-w-[26.25rem] max-h-[calc(100vh-9rem)] !z-[999999] !p-0 overflow-hidden relative overflow-y-auto'
    >
      <span className='sr-only'>{t('common:view_notifications')}</span>

      <div className='relative'>
        {notViewedCount ? (
          <>
            <Icon
              name='filled-bell'
              filled
              className='text-cool-900'
              size={16}
            />

            <div className='absolute -right-1 -top-1 flex h-[0.875rem] w-[0.875rem] items-center justify-center rounded-full border border-cool-50 bg-danger-dark'></div>
          </>
        ) : (
          <Icon
            name='bell-2'
            className='pointer-events-none text-cool-600'
            size={16}
          />
        )}
      </div>
    </TooltipMenu>
  )
}

import { viewNotifications } from 'api/notifications'
import {
  useInfiniteUserNotifications,
  useUserNotifications,
} from 'hooks/useUserNotifications'
import { useMutation } from 'react-query'

export function useMarkNotificationViewed() {
  const { refetch: refetchUserNotifications } = useUserNotifications()
  const { refetch: refetchInfiniteNotifications } =
    useInfiniteUserNotifications()

  return useMutation({
    mutationFn: async (notificationId: number) => {
      return viewNotifications({
        notificationIds: [notificationId],
        view: true,
      })
    },
    async onSuccess(data) {
      const { error, success } = data

      if (error) {
        throw new Error(error.message)
      }

      if (!success) {
        throw new Error('An error occured')
      }

      await Promise.allSettled([
        refetchInfiniteNotifications(),
        refetchUserNotifications(),
      ])
    },
  })
}

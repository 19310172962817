import { Notification } from 'api/notifications'
import classNames from 'classnames'
import { Tag } from 'components/notifications/Tag'
import useTranslation from 'next-translate/useTranslation'
import { PropsWithClassName } from 'types'
import { isNearlyGone } from 'utils/artwork'
import { formatShortRelativeTime } from 'utils/dates'
import {
  isFollowedArtistNotification,
  isSavedArtworkNotification,
} from 'utils/notifications'

const tagThemes = {
  default: 'group-hover/notification:bg-cool-50',
  gray: '!bg-cool-50',
  red: '!bg-danger-dark !text-cool-50',
  yellow: '!bg-warning-light !text-warning-dark',
} as const

export type TagTheme = keyof typeof tagThemes

type Props = Notification &
  PropsWithClassName & {
    includeDateTag?: boolean
    tagTheme?: TagTheme | null
    label?: string | null
  }

export function NotificationTags({
  type,
  artwork,
  createdAt,
  className,
  includeDateTag,
  tagTheme = 'default',
  label,
}: Props) {
  const { t } = useTranslation('notifications')
  const _isFollowedArtist = isFollowedArtistNotification(type)
  const _isSavedArtwork = isSavedArtworkNotification(type)
  const _isNearlyGone = isNearlyGone(artwork?.spreadPercentage)

  if (
    !_isSavedArtwork &&
    !_isFollowedArtist &&
    !_isNearlyGone &&
    !includeDateTag &&
    !label
  )
    return null

  return (
    <div className={className}>
      {(label || includeDateTag) && (
        <Tag
          className={classNames(
            'bg-warm-100 text-cool-800 transition-colors',
            tagTheme && tagThemes[tagTheme]
          )}
        >
          {/* if we have a label specified, we don't want to display the date tag, instead we want to display the label */}
          {!label && formatShortRelativeTime(new Date(createdAt))}
          {label}
        </Tag>
      )}

      {_isFollowedArtist && (
        <Tag className='bg-purple-50 text-purple-600'>
          {t('followed_artist')}
        </Tag>
      )}

      {_isSavedArtwork && (
        <Tag className='bg-purple-50 text-purple-600'>{t('saved_artwork')}</Tag>
      )}

      {_isNearlyGone && (
        <Tag className='bg-warning-light text-warning-dark'>
          {t('nearly_gone')}
        </Tag>
      )}
    </div>
  )
}

import { Notification, getUserNotifications } from 'api/notifications'
import { useAuth } from 'contexts'
import { useInfiniteQuery, useQuery } from 'react-query'

type UserNotificationsData = {
  notifications?: Notification[]
  notViewedCount?: number
}

export const useUserNotifications = () => {
  const { user } = useAuth()

  const query = useQuery<UserNotificationsData>(
    ['notifications', user?.id],
    async () => {
      const { results = [] } = await getUserNotifications({
        pageNumber: 1,
        pageSize: 6,
      })
      if (!results.length) return { notifications: [], notViewedCount: 0 }
      return {
        notifications: results,
        notViewedCount: results.reduce(
          (prev, currNotif) => prev + (currNotif.viewed ? 0 : 1),
          0
        ),
      }
    },
    { enabled: Boolean(user) }
  )

  return { ...query, data: query?.data || {} }
}

export function useInfiniteUserNotifications() {
  const { user } = useAuth()

  const query = useInfiniteQuery(
    ['infinite-notifications', user?.id],
    async ({ pageParam = 1 }) => {
      const { pagination, results = [] } = await getUserNotifications({
        pageNumber: pageParam,
        pageSize: 8,
      })

      return { pagination, notifications: results }
    },
    {
      enabled: Boolean(user),
      getNextPageParam: data => {
        if (!data?.pagination) return undefined

        const { pagination } = data

        if (pagination.page < pagination.pageCount) {
          return pagination.page + 1
        }

        return undefined
      },
    }
  )

  return query
}

export function useNotificationsGroup(groupId: number | null) {
  return useQuery(
    ['notifications-group', groupId],
    async () => {
      if (!groupId) return { notifications: [] }

      const { results = [] } = await getUserNotifications({
        pageNumber: 1,
        pageSize: 100,
        parentId: groupId,
      })

      return { notifications: results }
    },
    { refetchOnMount: true, refetchOnWindowFocus: false }
  )
}
